import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import PixieTranslationGenerated from './PixieTranslation.generated';

export enum PixieTypeOfContent
{
    IMAGES = 'IMAGES',
    VIDEO = 'VIDEO',

}
export enum PixieStatus
{
    PUBLISHED = 'PUBLISHED',
    DRAFT = 'DRAFT',

}

export class PixieGeneratedEntityManager<BT extends PixieTranslationGenerated, B extends PixieGenerated<BT>> extends EntityWithTranslationManager<BT, B>
{
    constructor(parameters: EntityWithTranslationManagerParameters<BT, B>)
    {
        super({
            name: parameters.name ?? 'Pixie',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'typeOfContent', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'dateAdded', type: BusinessEntityFieldType.DateTime, isInput: false },
				{ name: 'status', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'content', type: BusinessEntityFieldType.VarChar },
				{ name: 'isAutoPostingEnabled', type: BusinessEntityFieldType.Boolean },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'pixies',
            translationsCustomGraphQlFields: parameters.translationsCustomGraphQlFields,
        });
    }

}
export class PixieGenerated<BT extends PixieTranslationGenerated> extends BusinessEntityWithTranslation<BT>
{
	typeOfContent!: PixieTypeOfContent;
	dateAdded!: string;
	status: PixieStatus = PixieStatus.DRAFT;
	content: string = '';
	isAutoPostingEnabled: boolean = true;
	translationForeignKeyName: string = 'pixie';

}

export default PixieGenerated;